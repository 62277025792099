import { useLayoutEffect } from "react";

const News = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div className="main">
      <img
        className="header"
        src="/images/banner/yuki.jpg"
        alt="Tierheim mit Blumen"
      />
      <h2>Neues von Aegean Dogs</h2>
      <div className="news">
        <p>
          <b>März 2025:</b>
        </p>
        <p>
          Durch eure Spenden konnten unsere Mariendistel-Vorräte wieder aufgefüllt werden. Herzlichen Dank!
         </p>
      </div>
      <div className="news">
        <p>
          <b>Januar 2025:</b>
        </p>
        <p>
         Update 19.01.2025: Das Paypal-Konto kann wieder genutzt werden.
         </p>
         <p>
         Bitte beachten: Unser Paypal-Konto ist vorrübergehend nicht verfügbar.
         </p>
      </div>
      <div className="news">
        <p>
          <b>Dezember 2024:</b>
        </p>
        <p>
          Ihr Lieben, durch eure Materialspenden für Leinen sind über 50 Leinen für das Tierheim angefertigt worden. Herzlichen Dank!
         </p>
      </div>
      <div className="news">
        <p>
          <b>August 2024:</b>
        </p>
        <p>
          Wir bedanken uns sehr für die vielen Futterspender, die unserem Spendenaufruf bei VETO nachgekommen sind.
          Die Futterspende war wieder so umfangreich, dass sie von VETO kostenlos nach Griechenland geliefert wurde.
         </p>
      </div>
      <div className="news">
        <p>
          <b>April 2024:</b>
        </p>
        <p>
         Die Futtervorräte des Tierheims auf Aegina leeren sich. Wir starten
         wieder eine neue Sammelaktion bei VETO , um die Vorräte aufzufüllen. Wenn wir eine Futterlieferung, die 
         mehr als 1200 kg schwer ist, zusammenbekommen, dann ist der Transport nach
         Griechenland für uns kostenlos. Bitte guckt in unsere Wunschliste bei Veto und spendet Futter.<br></br>
         <span style={{fontWeight:"bold"}}>Gesammelt wird vom 10.04.24 - 09.07.24.</span>
         </p>
         <a
          style={{alignSelf: "center"}}
          href="https://www.veto-tierschutz.de/?wid=630820"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/images/veto.jpeg" alt="Link zur Wunschliste auf Veto." />
        </a>     
      </div>
      <div className="news">
        <p>
          <b>Januar 2024:</b>
        </p>
        <p>
         Im August 2023 riefen wir zu Futterspenden über VETO auf.
         Danke an alle, die über Veto Futter gespendet haben. Es sind tatsächlich 
         mehr als 2 Paletten an diversen Futter aus unserer Wunschliste zusammengekommen. 
         Dadurch wurde die Futterspende kostenlos nach Griechenland versendet und wir konnten
         unsere Vorräte wieder auffüllen.
         Wenn es notwendig wird, werden wir wieder einen Aufruf für Veto-Spenden hier und in unserer Facebook-Gruppe starten. 
         Es wäre super, wenn wir dann wieder innerhalb von 3 Monaten 2 Paletten zusammenbekommen,
         um die Transportkosten zu sparen.
        </p>
      </div>
      <div className="news">
        <p>
          <b>Dezember 2023:</b>
        </p>
        <p>
         Wir wünschen allen Frohe Weihnachten und einen guten Rutsch ins Neue Jahr!
         Das neue Jahr starten wir gleich mit einer tollen Aktion: Unsere Neujahrslose. 
         Lose gibt es ab sofort zu kaufen.
         Für mehr Informationen schaut bitte unter den Berichten.
        </p>
      </div>
      <div className="news">
        <p>
          <b>November 2023:</b>
        </p>
        <p>
          Wir haben unsere Homepage überarbeitet. Weiterhin begrüßen wir unseren
          neuen Webmaster, <b>Elke Börner</b>. Wir bedanken uns bei Sylvia
          Rottmann für die jahrelange fleißige Arbeit an der Homepage.
        </p>
      </div>
      <div className="news">
        <p>
          <b>März 2023:</b>
        </p>
        <p>
          <b>Amazon Smile stellt seine Tätigkeit ein.</b> Leider können Sie uns
          bei den Amazon-Einkäufen nicht mehr unterstützen. Weiterhin haben Sie
          aber noch die Möglichkeit bei Gooding und WeCanHelp / Bildungsspender
          mit jedem Einkauf auch Aegean Dogs zu unterstützen.
        </p>
      </div>
      <div className="news">
        <p>
          <b>Januar 2022:</b>
        </p>
        <p>
          Wir feiern unser 10-jähriges Bestehen. Mehr Informationen finden Sie{" "}
          <a
            href="/documents/10_Jahre_Aegean_Dogs.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            hier
          </a>
          .
        </p>
      </div>
      <div className="news">
        <p>
          <b>Januar 2021:</b>
        </p>
        <p>
          Unser Facebook-Flohmarkt wurde eingerichtet. Hier können nicht mehr
          gebrauchte Utensilien zu Gunsten von Aegean Dogs angeboten werden.
        </p>
      </div>
    </div>
  );
};

export default News;
