export const berichte = [
  {
    text: "VETO-Futterspende erfreut die Hunde im Tierheim",
    pdf: "25-03-01_Veto-Futterspende.pdf", 
    datum: "März 2025",
  },
  {
    text: "Lina, ein kleines Welpenmädchen, benötigt Unterstützung",
    pdf: "25-02-27_Lina.pdf", 
    datum: "Februar 2025",
  },
  {
    text: "Wir sammeln für eine Bestellung von dringend benötigte Mariendistel-Präperaten ",
    pdf: "25-02-19_Spendenaufruf Mariendistel.pdf", 
    datum: "Februar 2025",
  },
  {
    text: "Die Geschichte von Vegas - ein Rolli-Hund im Tierheim auf Aegina",
    pdf: "25.01.22_Vegas Geschichte.pdf", 
    datum: "Januar 2025",
  },
  {
    text: "Süßes blindes Katzenpärchen sucht ihr liebevolles Zuhause",
    pdf: "ToulaundAlati.pdf", 
    datum: "Januar 2025",
  },
  {
    text: "Ein Bericht vom Weihnachtsbasar 2024 auf Aegina",
    pdf: "24_12_22_Weihnachtsbasar 2024.pdf", 
    datum: "Dezember 2024",
  },
  {
    text: "Eure Futterspenden über VETO sind im Tierheim auf Aegina angekommen",
    pdf: "24_12_18_FutterspendeV Veto Aegean Dogs.pdf",
    datum: "Dezember 2024",
  },
  {
    text: "Wir sammeln für notwendige Reparaturen im Tierheim auf Aegina",
    pdf: "24_11_28_Reparaturen Tierheim.pdf",
    datum: "November 2024",
  },
  {
    text: "Wir sammeln Spenden für dringend benötigte Leinen",
    pdf: "24_10_26_Aufruf Leinen.pdf",
    datum: "Oktober 2024",
  },
  {
    text: "Toula und Alati bedanken sich für die Spenden",
    pdf: "24_10_26 Toula und Alati-Story.pdf",
    datum: "Oktober 2024",
  },
  {
    text: "Blindes Pflegekätzchen braucht Hilfe",
    pdf: "24_08_30_Pflegekatze_benötigt_AugenOP.jpg",
    datum: "August 2024",
  },
  {
    text: "Veto-Spende August 2024",
    pdf: "Veto-Lieferung_August24.jpg",
    datum: "August 2024",
  },
  {
    text: "Spendentransport Juni 2024",
    pdf: "24_06_15 Spendentransport Juni 2024.pdf",
    datum: "Juni 2024",
  },
  {
    text: "Mitgliederversammlung Aegean Dogs am 09.06.2024",
    pdf: "24_06_09_Mitgliederversammlung Aegean Dogs.pdf",
    datum: "Juni 2024",
  },
  {
    text: "Die Gewinner der Verlosung stehen fest",
    pdf: "24_01_16-Lose_wurden_gezogen.jpg",
    datum: "Jan. 2024",
  },
  {
    text: "Futterlieferung von Veto ist in Griechenland angekommen",
    pdf: "24-01-08 Veto_liefert_nach_Griechenland.pdf",
    datum: "Jan. 2024",
  },
  {
    text: "Lose 2024",
    pdf: "Lose 2024.pdf",
    datum: "Dez. 2023",
  },
  {
    text: "Der krönende Jahresabschluss für unser Tierheim",
    pdf: "Der krönende Jahresabschluss für unser Tierheim.pdf",
    datum: "Dez. 2023",
  },
  {
    text: "Weihnachtsbasar auf Aegina",
    pdf: "Basar_Dezember_2023.pdf",
    datum: "Dez. 2023",
  },
  {
    text: "Ein sehr informativer Jahresrückblick einer langjährigen Freundin des Tierheims",
    pdf: "2023 HUNDEBERICHT-Endfassung-2.pdf",
    datum: "Dez. 2023",
  },
  {
    text: "Aeneas hat es leider nicht geschafft",
    pdf: "23_11_22_aeneas-3.pdf",
    datum: "Nov. 2023",
  },
  {
    text: "Spendenaufuf für Aeneas",
    pdf: "23_11_20_aeneas-2.jpg",
    datum: "Nov. 2023",
  },
  {
    text: "Aeneas - eine Gräueltat an einen Straßenhund",
    pdf: "23_11_17_aeneaas.pdf",
    datum: "Nov. 2023",
  },
  {
    text: "Das neue Katzengehege wurde bezogen",
    pdf: "23_11_01_katzengehege.pdf",
    datum: "Nov. 2023",
  },
  {
    text: "Spendenaufruf aus der Facebook-Gruppe",
    pdf: "23_05_15_spendenaufruf-gross.jpg",
    datum: "Mai 2023",
  },
  {
    text: "Das Tierheim hat die Kapazitätsgrenze erreicht",
    pdf: "23_03_12_aufnahmestopp.jpg",
    datum: "März 2023",
  },
  {
    text: "Spendenaufruf für ein Katzengehege",
    pdf: "23_02_04_katzengehege.pdf",
    datum: "Feb. 2023",
  },
  {
    text: "Vereinsspende - Zahltag von Netto",
    pdf: "23_01_07_zahltag_netto.jpg",
    datum: "Jan. 2023",
  },
  {
    text: "Zeckenschutz - jetzt Vorsorge treffen fürs Frühjahr ...",
    pdf: "Zeckenschutz.pdf",
    datum: "Jan. 2023",
  },
  {
    text: "Unterstützung bei der Straßenkatzenfütterung",
    pdf: "22_12_06_katzenfuettern.pdf",
    datum: "Dez. 2022",
  },
  {
    text: "Die gespendeten Hundehütten sind fertig",
    pdf: "22_09_22_immobilien2022.pdf",
    datum: "Sept. 2022",
  },
  {
    text: "Neue Solaranlage geht in Betrieb ...",
    pdf: "Neue-Solaranlage.pdf",
    datum: "Sept. 2022",
  },
  {
    text: "Vereinsspende bei Netto",
    pdf: "22_09_20_netto.pdf",
    datum: "Sept. 2022",
  },
  {
    text: "Das Pistazienfest 2022 auf Aegina - Ein Bericht ...",
    pdf: "Pistazienfest-2022.pdf",
    datum: "Sept. 2022",
  },
  {
    text: "Fanis, ein Hundeschicksal ...",
    pdf: "Fanis.pdf",
    datum: "Sept. 2022",
  },
  {
    text: "Renovierungen im Tierheim ...",
    pdf: "Renovierungen.pdf",
    datum: "Aug. 2022",
  },
  {
    text: "Neuzugaenge im Tierheim ...",
    pdf: "Neuzugaenge.pdf",
    datum: "Aug. 2022",
  },
  {
    text: "Heiße Sommer, Waldbrandgefahr rund ums Tierheim ...",
    pdf: "Waldbrandgefahr.pdf",
    datum: "Aug. 2022",
  },
  {
    text: "Spende eine Hundehütte",
    pdf: "22_07_07_hundehuetten2022.pdf",
    datum: "Juli 2022",
  },
  {
    text: "Tierheimbericht der ersten Vorsitzenden",
    pdf: "22_06_24_tierheimbericht2022.pdf",
    datum: "Juni 2022",
  },
  {
    text: "Indigo, ein Hundeschicksal ...",
    pdf: "Indigo.pdf",
    datum: "Jan. 2022",
  },
  {
    text: "Bericht vom Erntedankfest in Berlin, wir waren dabei ...",
    pdf: "Erntedankfest.pdf",
    datum: "Sept. 2019",
  },
];
